var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"New"},[(_vm.render)?_c('v-container',{staticClass:"py-0",class:{ 'yellow-element': _vm.newPost.backgroundColor == '#FFF171' },attrs:{"fluid":""}},[_c('v-row',{style:('background-color: ' + _vm.newPost.backgroundColor)},[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12","sm":"7"}},[(_vm.newPost.cover != null)?[_c('v-img',{staticClass:"img-cover",attrs:{"src":'https://www.ofisdesigncms.actstudio.xyz/' + _vm.newPost.cover.path}})]:_vm._e()],2),_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12","sm":"5"}},[_c('v-row',{staticClass:"fill-height",attrs:{"no-gutters":"","justify-sm":"center","align":"center"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('div',{staticClass:"div-text-cover-max-width"},[_c('span',{staticClass:"d-block titles-card-projects"},[_vm._v(_vm._s(_vm.formatDate(_vm.newPost.datePost)))]),_c('span',{staticClass:"d-block black--text aeonik-bold titles my-4 my-sm-8 line-height-521351"},[_vm._v(_vm._s(_vm.newPost.description))]),_c('span',{staticClass:"mt-5 texts-news--span in-new-post aeonik-regular black--text titles-card-projects"},[_vm._v(_vm._s(_vm.newPost.type.name))])])])],1)],1)],1),_c('v-row',{staticClass:"pb-10 py-xl-12",class:{
        'background-yellow-rgba': _vm.newPost.backgroundColor == '#FFF171',
        'background-cream-rgba': _vm.newPost.backgroundColor == '#FFF0D9',
        'background-blue-rgba': _vm.newPost.backgroundColor == '#223151',
        'background-green-rgba': _vm.newPost.backgroundColor == '#1F6D5E',
        'background-light-green-rgba': _vm.newPost.backgroundColor == '#6CD3BF',
        'background-gray-rgba': _vm.newPost.backgroundColor == '#B2B2B2',
      },attrs:{"justify":"center"}},[_c('v-col',{staticClass:"py-8 py-md-12 padding-content-mobile",attrs:{"cols":"12","sm":"10","xl":"8"}},_vm._l((_vm.newPost.content),function(row,r){return _c('v-row',{key:r,attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pb-0 pb-md-2 pb-lg-5",attrs:{"cols":"12","sm":12 / row.value.length}},[_vm._l((row.value),function(column,c){return [_vm._l((column.children),function(children,ch){return [_c('div',{key:c + '-' + ch},[(children.component == 'text')?[_c('div',{domProps:{"innerHTML":_vm._s(children.settings.text)}})]:(children.component == 'image')?[(children.settings.image != null)?[(_vm.isVideo(children.settings.image.path))?[_c('div',{staticClass:"position-relative"},[_c('video',{staticClass:"Project__row-content--image",attrs:{"muted":""},domProps:{"muted":true}},[_c('source',{attrs:{"type":"video/mp4","src":'https://www.ofisdesigncms.actstudio.xyz/' +
                                children.settings.image.path}})]),_c('v-img',{staticClass:"Project__row-content--play toClick",attrs:{"src":require("@/assets/icons/play.svg")}})],1)]:[_c('v-img',{staticClass:"Project__row-content--image",attrs:{"src":'https://www.ofisdesigncms.actstudio.xyz/' +
                            children.settings.image.path}})]]:_vm._e()]:_vm._e()],2)]})]})],2)],1)}),1)],1)],1):_vm._e(),_c('v-container',{staticClass:"px-8 px-sm-12 px-md-3 py-0"},[_c('v-row',{staticClass:"Project__row-related-projects animation-lazy d-none d-sm-flex mb-7 mb-xl-12 pb-xl-7"},[_c('v-col',{staticClass:"mt-md-5",attrs:{"cols":"12"}},[_c('span',{staticClass:"aeonik-bold titles"},[_vm._v("Publicaciones relacionadas")])]),_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[(_vm.renderNews)?[_c('carousel',{attrs:{"items":3,"autoplay":false,"dots":false,"margin":50,"autoHeight":true,"nav":false,"mouseDrag":false,"responsive":{ 0: { items: 1 }, 600: { items: 3 } },"slideTransition":"linear"}},_vm._l((_vm.news),function(newPost,np){return _c('v-card',{key:np + '-desk',attrs:{"to":'/new/'+newPost.url,"ripple":false,"tile":"","elevation":"0"}},[_c('div',{staticClass:"activate-overlay"},[_c('div',[(newPost.cover != null)?[_c('v-img',{attrs:{"src":'https://www.ofisdesigncms.actstudio.xyz/' +
                            newPost.cover.path,"aspect-ratio":16 / 16,"height":"100%","width":"100%"}})]:_vm._e()],2),_c('div',{staticClass:"overlay toClick"},[_c('div',[_c('span',{staticClass:"capitalize titles aeonik-bold"},[_vm._v(_vm._s(newPost.description))])])])])])}),1)]:_vm._e()],2)],1)],1),_c('div',{staticClass:"div-float"})],1)],1),_c('v-container',{staticClass:"py-xl-12 d-sm-none"},[_c('v-row',{staticClass:"New__row-related-projects animation-lazy my-4 my-sm-7 my-xl-12",attrs:{"justify":"center"}},[_c('v-col',{staticClass:"pl-8 pr-0",attrs:{"cols":"12","sm":"10","md":"12"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12"}},[_c('span',{staticClass:"aeonik-bold titles"},[_vm._v("Publicaciones relacionadas")])]),_c('v-col',{staticClass:"pa-0 pa-md-3",attrs:{"cols":"12"}},[_c('v-row',{staticClass:"pt-6 pt-sm-12 pt-md-3 pt-xl-5",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-sheet',[_c('v-slide-group',{attrs:{"show-arrows":false,"center-active":""},model:{value:(_vm.relatedNewsSlide),callback:function ($$v) {_vm.relatedNewsSlide=$$v},expression:"relatedNewsSlide"}},_vm._l((_vm.news),function(newPost,np){return _c('v-slide-item',{key:np},[_c('v-card',{staticClass:"New__row-related-projects--card",attrs:{"to":'/new/' + newPost.url,"elevation":"0"}},[_c('div',{staticClass:"activate-overlay"},[_c('div',[(newPost.cover != null)?[_c('v-img',{staticClass:"activate-overlay__element",attrs:{"src":'https://www.ofisdesigncms.actstudio.xyz/' +
                                  newPost.cover.path}})]:_vm._e()],2),_c('div',{staticClass:"overlay toClick"},[_c('div',[_c('span',{staticClass:"capitalize titles aeonik-bold"},[_vm._v(_vm._s(newPost.description))])])])])])],1)}),1)],1)],1)],1)],1)],1)],1),_c('div',{staticClass:"div-float"})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }