<template>
  <div class="New">
    <v-container
      v-if="render"
      v-bind:class="{ 'yellow-element': newPost.backgroundColor == '#FFF171' }"
      fluid
      class="py-0"
    >
      <v-row v-bind:style="'background-color: ' + newPost.backgroundColor">
        <v-col cols="12" sm="7" class="pa-0">
          <template v-if="newPost.cover != null">
            <v-img
              :src="
                'https://www.ofisdesigncms.actstudio.xyz/' + newPost.cover.path
              "
              class="img-cover"
            ></v-img>
          </template>
        </v-col>
        <v-col cols="12" sm="5" class="pa-0">
          <v-row
            no-gutters
            justify-sm="center"
            align="center"
            class="fill-height"
          >
            <v-col cols="auto">
              <div class="div-text-cover-max-width">
                <span class="d-block titles-card-projects">{{
                  formatDate(newPost.datePost)
                }}</span>

                <span
                  class="d-block black--text aeonik-bold titles my-4 my-sm-8 line-height-521351"
                  >{{ newPost.description }}</span
                >

                <span
                  class="mt-5 texts-news--span in-new-post aeonik-regular black--text titles-card-projects"
                  >{{ newPost.type.name }}</span
                >
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <!-- content -->
      <v-row
        justify="center"
        class="pb-10 py-xl-12"
        v-bind:class="{
          'background-yellow-rgba': newPost.backgroundColor == '#FFF171',
          'background-cream-rgba': newPost.backgroundColor == '#FFF0D9',
          'background-blue-rgba': newPost.backgroundColor == '#223151',
          'background-green-rgba': newPost.backgroundColor == '#1F6D5E',
          'background-light-green-rgba': newPost.backgroundColor == '#6CD3BF',
          'background-gray-rgba': newPost.backgroundColor == '#B2B2B2',
        }"
      >
        <v-col
          cols="12"
          sm="10"
          xl="8"
          class="py-8 py-md-12 padding-content-mobile"
        >
          <v-row no-gutters v-for="(row, r) in newPost.content" v-bind:key="r">
            <v-col
              cols="12"
              :sm="12 / row.value.length"
              class="pb-0 pb-md-2 pb-lg-5"
            >
              <template v-for="(column, c) in row.value">
                <template v-for="(children, ch) in column.children">
                  <div :key="c + '-' + ch">
                    <template v-if="children.component == 'text'">
                      <div v-html="children.settings.text"></div>
                    </template>
                    <template v-else-if="children.component == 'image'">
                      <template v-if="children.settings.image != null">
                        <template v-if="isVideo(children.settings.image.path)">
                          <div class="position-relative">
                            <video muted class="Project__row-content--image">
                              <source
                                type="video/mp4"
                                :src="
                                  'https://www.ofisdesigncms.actstudio.xyz/' +
                                  children.settings.image.path
                                "
                              />
                            </video>
                            <v-img
                              src="@/assets/icons/play.svg"
                              class="Project__row-content--play toClick"
                            ></v-img>
                          </div>
                        </template>
                        <template v-else>
                          <v-img
                            :src="
                              'https://www.ofisdesigncms.actstudio.xyz/' +
                              children.settings.image.path
                            "
                            class="Project__row-content--image"
                          ></v-img>
                        </template>
                        <!-- isVideo -->
                      </template>
                    </template>
                  </div>
                </template>
              </template>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <!-- /content -->
    </v-container>

    <v-container class="px-8 px-sm-12 px-md-3 py-0">
      <!-- **********************inicia proyectos relacionados**************** -->
      <v-row
        class="Project__row-related-projects animation-lazy d-none d-sm-flex mb-7 mb-xl-12 pb-xl-7"
      >
        <v-col cols="12" class="mt-md-5">
          <span class="aeonik-bold titles">Publicaciones relacionadas</span>
        </v-col>
        <v-col cols="12">
          <v-row>
            <v-col cols="12">
              <template v-if="renderNews">
                <carousel
                  :items="3"
                  :autoplay="false"
                  :dots="false"
                  :margin="50"
                  :autoHeight="true"
                  :nav="false"
                  :mouseDrag="false"
                  :responsive="{ 0: { items: 1 }, 600: { items: 3 } }"
                  slideTransition="linear"
                >
                  <v-card
                    v-for="(newPost, np) in news"
                    v-bind:key="np + '-desk'"
                    v-bind:to="'/new/'+newPost.url"
                    v-bind:ripple="false"
                    class=""
                    tile
                    elevation="0"
                  >
                    <!-- Project__row-related-projects--card -->
                    <div class="activate-overlay">
                      <div>
                        <template v-if="newPost.cover != null">
                          <v-img
                            v-bind:src="
                              'https://www.ofisdesigncms.actstudio.xyz/' +
                              newPost.cover.path
                            "
                            v-bind:aspect-ratio="16 / 16"
                            height="100%"
                            width="100%"
                            class=""
                          />
                          <!-- activate-overlay__element -->
                        </template>
                      </div>
                      <!-- overlay -->
                      <div class="overlay toClick">
                        <div>
                          <span class="capitalize titles aeonik-bold">{{
                            newPost.description
                          }}</span>
                        </div>
                      </div>
                      <!-- /overlay -->
                    </div>
                  </v-card>
                </carousel>
              </template>
            </v-col>
          </v-row>
        </v-col>
        <div class="div-float"></div>
      </v-row>
      <!-- **********************/termina news relacionadas**************** -->
    </v-container>

    <v-container class="py-xl-12 d-sm-none">
      <!-- **********************inicia proyectos relacionados**************** -->
      <v-row
        class="New__row-related-projects animation-lazy my-4 my-sm-7 my-xl-12"
        justify="center"
      >
        <v-col cols="12" sm="10" md="12" class="pl-8 pr-0">
          <v-row no-gutters>
            <v-col cols="12" class="pt-0">
              <span class="aeonik-bold titles">Publicaciones relacionadas</span>
            </v-col>
            <v-col cols="12" class="pa-0 pa-md-3">
              <v-row no-gutters class="pt-6 pt-sm-12 pt-md-3 pt-xl-5">
                <v-col cols="12">
                  <v-sheet>
                    <v-slide-group
                      v-model="relatedNewsSlide"
                      v-bind:show-arrows="false"
                      center-active
                    >
                      <v-slide-item
                        v-for="(newPost, np) in news"
                        v-bind:key="np"
                      >
                        <v-card
                          :to="'/new/' + newPost.url"
                          class="New__row-related-projects--card"
                          elevation="0"
                        >
                          <div class="activate-overlay">
                            <div>
                              <template v-if="newPost.cover != null">
                                <v-img
                                  :src="
                                    'https://www.ofisdesigncms.actstudio.xyz/' +
                                    newPost.cover.path
                                  "
                                  class="activate-overlay__element"
                                ></v-img>
                              </template>
                            </div>
                            <!-- overlay -->
                            <div class="overlay toClick">
                              <div>
                                <span class="capitalize titles aeonik-bold">{{
                                  newPost.description
                                }}</span>
                              </div>
                            </div>
                            <!-- /overlay -->
                          </div>
                        </v-card>
                      </v-slide-item>
                    </v-slide-group>
                  </v-sheet>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
        <div class="div-float"></div>
      </v-row>
      <!-- **********************/termina proyectos relacionados**************** -->
    </v-container>
  </div>
</template>

<script>
import carousel from "vue-owl-carousel";

export default {
  components: {
    carousel,
  },
  data() {
    return {
      render: false,
      renderNews: false,
      newPost: {},
      relatedNewsSlide: 0,
      news: [],
    };
  },
  watch: {
    $route(to, from) {
      //fetch para traer una noticia
      fetch(
        "https://www.ofisdesigncms.actstudio.xyz/api/collections/get/news?token=e96ec97538f5b51ad80b44386e6d1c",
        {
          method: "post",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            filter: {
              url: this.$route.params.newPost,
            },
            populate: 2,
          }),
        }
      )
        .then((res) => res.json())
        .then((res) => (this.newPost = res.entries[0]))
        .then((res) => (this.render = true))
        .catch((error) => console.log(error.message));
    },
  },
  beforeCreate() {
    //fetch para traer una noticia
    fetch(
      "https://www.ofisdesigncms.actstudio.xyz/api/collections/get/news?token=e96ec97538f5b51ad80b44386e6d1c",
      {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          filter: {
            url: this.$route.params.newPost,
          },
          populate: 2,
        }),
      }
    )
      .then((res) => res.json())
      .then((res) => (this.newPost = res.entries[0]))
      .then((res) => (this.render = true))
      .catch((error) => console.log(error.message));

    //fetch para las noticias
    fetch(
      "https://www.ofisdesigncms.actstudio.xyz/api/collections/get/news?token=e96ec97538f5b51ad80b44386e6d1c",
      {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          filter: {},
          sort: { datePost: -1 },
          populate: 2,
          limit: 2,
        }),
      }
    )
      .then((res) => res.json())
      .then((res) => (this.news = res.entries))
      .then((res) => (this.renderNews = true))
      .catch((error) => console.log(error.message));
  },
  mounted() {
    this.$emit("update", 1);
  },
  methods: {
    isVideo: function (path) {
      return path.substring(path.length - 4) == ".mp4";
    },
    formatDate: function (datePost) {
      var dateToFormat = new Date(datePost);
      var month = dateToFormat.getMonth();
      var months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];

      var formatedDate = months[month] + " " + dateToFormat.getFullYear();

      return formatedDate;
    },
  },
};
</script>